// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-checkbox {
  display: flex;
  align-content: flex-start;
  margin: 10px 8px auto 0;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
}
.mat-mdc-checkbox .mat-checkbox-layout {
  white-space: normal !important;
}
.mat-mdc-checkbox .mat-checkbox-inner-container {
  margin-top: 5px;
}

mat-tab-group .mdc-tab {
  font-weight: bold;
  color: #000000;
}
mat-tab-group .mdc-tab--active {
  color: #0066b2;
}

mat-expansion-panel-header .mat-content {
  flex: 0;
  flex-basis: content;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #0066b2;
  color: white;
}

mat-radio-button .mat-mdc-radio-button .mat-radio-outer-circle {
  height: 15px;
  width: 15px;
  border-color: #0066b2;
}
mat-radio-button .mat-mdc-radio-button .mat-radio-label-content {
  font-size: 9px;
  padding-left: 0px;
}
mat-radio-button .mat-mdc-radio-button .mat-radio-label {
  white-space: inherit;
  vertical-align: 0%;
  line-height: initial;
}

.mat-select-panel mat-option.mat-mdc-option {
  margin: auto 0;
  padding: 10px 20px;
  overflow: visible;
  line-height: 130%;
  word-wrap: break-word;
  white-space: pre-wrap;
  height: 100%;
}

.mat-snack-bar-warning .mdc-snackbar__surface {
  background: rgb(255, 248, 115) !important;
}

.mat-snack-bar-warning .mdc-snackbar__label {
  color: black !important;
  font-weight: bold !important;
}

.mat-snack-bar-error {
  background-color: #97002e;
}
.mat-snack-bar-error .mdc-snackbar__surface {
  --mdc-snackbar-container-color: \$esra-red;
  --mat-mdc-snack-bar-button-color: rgb(250, 193, 192);
}

.mat-mdc-card {
  box-shadow: none;
}

.dialogButtons {
  padding-top: 10px;
  float: right;
}

.button-blue {
  background-color: #0066b2;
  border: 1px solid #0066b2;
  color: #ffffff;
}
.button-blue:hover {
  background-color: #0b2d71;
  cursor: pointer;
}

.button {
  margin: none;
}

.text-button {
  display: inline-block;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: #0066b2;
  font-family: "GothamBold", Arial, sans-serif;
}
.text-button:hover {
  color: #0b2d71;
  box-shadow: inset 0 -2px 0 0 #0b2d71;
}

.close-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.close-button::before {
  font-family: "Material Icons";
  content: "close";
}
.close-button:hover {
  background-color: rgba(0, 0, 0, 0.16);
  color: #0b2d71;
}

:root ngx-slider {
  transition: none;
  margin: 15px 0px;
  height: 40px;
}
:root ngx-slider .ngx-slider-pointer {
  width: 12px;
  height: 12px;
  top: auto;
  bottom: 26px;
  background-color: #0066b2;
}
:root ngx-slider .ngx-slider-bubble {
  top: auto;
  bottom: 0px;
}
:root ngx-slider .ngx-slider-span {
  color: rgba(0, 0, 0, 0.8705882353);
  font: normal normal medium "Gotham", Arial, sans-serif;
  font-size: 11px;
}
:root ngx-slider .ngx-slider-pointer:after {
  display: none;
}
:root ngx-slider .ngx-slider-bar {
  height: 2px;
  background: rgba(125, 127, 134, 0.7019607843);
  top: auto;
  bottom: 7px;
}
:root ngx-slider .ngx-slider-selection {
  background: #0066b2;
  top: auto;
  bottom: 7px;
}

app-welding-search .mat-mdc-paginator .mat-mdc-paginator-container {
  display: flex;
  justify-content: center;
}

/* You can add global styles to this file, and also import other style files */
.left-align {
  float: left;
}

.right-align {
  float: right;
}

.text-left-align {
  text-align: left !important;
}

.text-right-align {
  text-align: right !important;
}

.dialogClose {
  float: right;
  cursor: pointer;
}

.dialogClose div {
  text-align: center;
  margin-top: -6px;
}

.dialogmainheader {
  padding-top: 30px;
}

.dialogmain {
  border: 1px solid gray;
  min-height: 300px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -15px;
}

.button:enabled,
.primary:enabled,
.secondary:enabled {
  border: 1px solid #0066b2;
}

.button:disabled,
.primary:disabled,
.secondary:disabled {
  border: 1px solid #e3e3e3;
  cursor: default;
}

.container-horizontal {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.required:after {
  content: " *";
  color: red;
}

.pointer {
  cursor: pointer;
}

.progress-bar-container {
  min-height: 6px;
  margin: 2% 3%;
}
.progress-bar-container div {
  height: 5px;
}

.esra-select {
  width: 80%;
}

.width-10 {
  width: 10%;
}

.esra-tooltip {
  white-space: pre-line;
}

.edit-color {
  color: #0066b2;
}

.delete-color {
  color: #e21836;
}

.mat-sort-header-arrow {
  opacity: 1 !important;
  transform: none;
  color: black;
}

.wrapped-text {
  padding: 1% 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.main-container {
  padding: 2%;
}

.common-notes-search-input-container > input {
  width: 100%;
}

.temperature-toggle-container {
  margin: 10px 0;
}

mat-header-row {
  min-height: 40px;
}
mat-header-row .mat-mdc-header-cell {
  background-color: #e3e3e3;
  border: 1px solid #8c8f93;
  border-collapse: collapse;
  font-weight: bold;
  font-size: larger;
}

.mdc-switch__track {
  border-radius: 14px;
  height: 22px;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #0066B2;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #0066B2;
  --mdc-switch-selected-pressed-state-layer-color: #0066B2;
  --mdc-switch-selected-focus-handle-color: #ffffff;
  --mdc-switch-selected-hover-handle-color: #ffffff;
  --mdc-switch-selected-pressed-handle-color: #ffffff;
  --mdc-switch-selected-focus-track-color: #0066B2;
  --mdc-switch-selected-hover-track-color: #0066B2;
  --mdc-switch-selected-pressed-track-color: #0066B2;
  --mdc-switch-selected-track-color: #0066B2 ;
  --mdc-switch-unselected-focus-state-layer-color: #0066B2;
  --mdc-switch-unselected-handle-color: #ffffff;
  --mdc-switch-unselected-hover-state-layer-color: #0066B2;
  --mdc-switch-unselected-pressed-state-layer-color: #0066B2;
  --mdc-switch-unselected-focus-handle-color: #ffffff;
  --mdc-switch-unselected-hover-handle-color: #ffffff;
  --mdc-switch-unselected-pressed-handle-color: #ffffff;
  --mdc-switch-unselected-focus-track-color: #0066B2;
  --mdc-switch-unselected-hover-track-color: #0066B2;
  --mdc-switch-unselected-pressed-track-color: #0066B2;
  --mdc-switch-unselected-track-color: #0066B2;
}

table {
  border: solid 1px #e3e3e3;
}

thead {
  min-height: 40px;
  background-color: #e3e3e3;
  border-collapse: collapse;
  font-weight: bold;
  font-size: larger;
}
thead th {
  border: 1px solid #8c8f93;
  padding: 10px 20px;
}

td {
  padding: 0;
  border-top: 1px solid #8c8f93;
  border-bottom: 1px solid #8c8f93;
}
td input {
  height: 100%;
  width: 100%;
  border: none;
  font-weight: bold;
  padding: 15px 10px;
  background-color: transparent;
}
td input:focus {
  outline: none;
}

.error-state {
  border-color: red;
}

.right-border {
  border-right: 1px solid #8c8f93;
}

.left-border {
  border-left: 1px solid #8c8f93;
}

mat-cell input {
  height: 100%;
  width: 100%;
  border: none;
  font-weight: bold;
}
mat-cell input:focus {
  outline: none;
}

mat-row {
  border-collapse: collapse;
  align-items: stretch;
}
mat-row mat-cell {
  border: 1px solid #8c8f93;
}

.main-container .top-container {
  margin-top: 10px;
  margin-left: 10px;
}
.main-container .top-container #title {
  font-size: xx-large;
  padding-right: 0%;
  float: left;
}
.main-container .top-container #mat-close-btn {
  height: 36px;
  width: 20px;
  float: right;
  border: none;
  color: black;
  background-color: white;
}
.main-container mat-table {
  border: solid 1px #e3e3e3;
}

.legacy-data {
  margin: 20px 20px;
}
.legacy-data p {
  margin: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 5px 15px;
  width: 100%;
}
.input-container .mat-mdc-form-field {
  min-width: 100%;
}
.input-container .mat-form-field-wrapper {
  padding: none;
}

.highlight-cell-class {
  border: 3px solid red !important;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.buttons-container button {
  width: 40%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
