import { Component, OnInit, HostListener } from '@angular/core';
import {
  BranchTableCodeViewModel,
  BranchTableViewModel,
  EsraPipingAPIClient,
  BranchTableCellViewModel,
  CommonNoteViewModel,
} from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { faX } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-branch-create',
  templateUrl: './branch-create.component.html',
  styleUrls: ['./branch-create.component.scss'],
})
export class BranchCreateComponent implements OnInit {
  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  //constants
  unassigned = '  ';
  space = 'X';
  createBranchTitle = 'Create new branch table';
  editBranchTitle = 'Edit branch table';
  missingTitleValueMessage = 'The branch table requires a title';
  greaterThanZeroAndDivisibleByQuarterMessage =
    'This value must be greater than 0 and divisible by 0.25';
  missingCellsMessage = 'All cells must contain a code in the table';
  confirmationDialogMessage =
    'Are you sure you want to exit? Any unsaved changes will be lost.';
  fortyFiveDegrees = '45 degrees';
  nintyDegrees = '90 degrees';

  inputFormGroup: UntypedFormGroup;
  rowCount = 1;
  colCount = 1;
  branchHeaderSizes: number[] = [];
  hiddenRowsArray: number[] = [];
  addedRowsArray: number[] = [];
  selectedCodesStringArray: string[] = [];
  cellArray: BranchTableCellViewModel[][] = [];
  highlightedCellArray: BranchTableCellViewModel[] = [];
  startCellHeaderCoord: number;
  startCellBranchCoord: number;
  currentCellHeaderCoord: number;
  currentCellBranchCoord: number;
  endPositionCell: BranchTableCellViewModel;
  availableCodesArray: BranchTableCodeViewModel[] = [];
  commonNotesAll: CommonNoteViewModel[] = [];
  commonNotesSelected: CommonNoteViewModel[] = [];
  commonNotesFiltered: CommonNoteViewModel[] = [];
  codeCommonNotesMap: Map<string, CommonNoteViewModel[]> = new Map<
    string,
    CommonNoteViewModel[]
  >();
  legendBranchTableCodes: BranchTableCodeViewModel[] = [];

  inputBranchTable: BranchTableViewModel;
  submissionBranchTableVM = new BranchTableViewModel();

  faX = faX;

  //form controls
  tableName: string;
  branchHeaderMax = 36;
  branchHeaderMin = 0.75;
  addRowValue: number;
  hideRowValue: number;
  tableCodeInput: string;
  matSelectControl: CommonNoteViewModel[];
  legendMatSelectControl: CommonNoteViewModel[];
  typeToggleControl: boolean;

  //state booleans
  createMode = true;
  setTableReady = false;
  readyState = true;
  highlightState = false;
  inputState = false;
  setTableClicked = false;
  tableEditMode = false;
  saveClicked = false;
  missingCells = true;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (this.highlightState) {
        this.highlightState = false;
        this.inputState = false;
        this.readyState = true;
      }
    }
  }

  ngOnInit(): void {
    this.getStoredBranchTable();
    this.initializeFormControls();
    this.setMode();
    this.startupPopulateCalls();
    if (!this.createMode) {
      this.populateEditModeFields();
    }
  }

  private getStoredBranchTable() {
    const storedBranchTable = localStorage.getItem('branchTable');
    if (storedBranchTable) {
      this.inputBranchTable = JSON.parse(storedBranchTable);
      localStorage.removeItem('branchTable');
    }
  }

  private startupPopulateCalls(): void {
    if (this.createMode) {
      this.populateBranchHeaderSizes();
    }
    this.populateAvailableCodesArray();
    this.populateCommonNotesAllArray();
  }

  private setMode(): void {
    if (this.inputBranchTable) {
      this.createMode = false;
    }
  }

  private populateEditModeFields(): void {
    this.tableName = this.inputBranchTable.name;
    this.inputFormGroup.controls['tableName'].setValue(
      this.inputBranchTable.name
    );
    this.calculateMinMaxHeaderSizes();
    this.setTable();
  }

  public getTitle(): string {
    if (this.createMode) {
      return this.createBranchTitle;
    } else {
      return this.editBranchTitle;
    }
  }

  private calculateMinMaxHeaderSizes(): void {
    this.branchHeaderMax = this.inputBranchTable.cells[0].headerSize;
    this.branchHeaderMin =
      this.inputBranchTable.cells[
        this.inputBranchTable.cells.length - 1
      ].headerSize;
  }

  private initializeFormControls(): void {
    this.inputFormGroup = new UntypedFormGroup({
      tableName: new UntypedFormControl(this.tableName, [
        Validators.required,
        this.nonEmptyStringValidator,
      ]),
      minControl: new UntypedFormControl(this.branchHeaderMin, [
        Validators.required,
        this.greaterThanZeroAndDivisibleByQuarterValidator,
      ]),
      maxControl: new UntypedFormControl(this.branchHeaderMax, [
        Validators.required,
        this.greaterThanZeroAndDivisibleByQuarterValidator,
      ]),
      addControl: new UntypedFormControl(this.addRowValue),
      hideControl: new UntypedFormControl(this.hideRowValue),
      cellInputControl: new UntypedFormControl(this.tableCodeInput),
      matSelectControl: new UntypedFormControl(this.matSelectControl),
      typeToggleControl: new UntypedFormControl(this.typeToggleControl),
      legendMatSelectControl: new UntypedFormControl(
        this.legendMatSelectControl
      )
    });
  }

  private updateFormControlsWithLegendCodes(): void {
    const namePrefix = 'legendMatSelectControl';
    for (const code of this.legendBranchTableCodes) {
      this.inputFormGroup.addControl(
        namePrefix + code.code,
        new UntypedFormControl(code.commonNotes)
      );
    }
  }

  private populateBranchHeaderSizes(): void {
    this.esraApiClient.getAllBranchSizeOptions().subscribe({
      next: (data) => {
        this.branchHeaderSizes = data;
        this.setTableReady = true;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  private populateAvailableCodesArray(): void {
    this.esraApiClient.getAllUniqueBranchTableCodes().subscribe({
      next: (data) => {
        this.availableCodesArray = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  private populateCommonNotesAllArray(): void {
    this.esraApiClient.getAllCommonNotes().subscribe({
      next: (data) => {
        this.commonNotesAll = data;
        this.commonNotesFiltered = data;
        if (!this.createMode) {
          this.populateSelectedCommonNotes();
          this.populateSelectedLegendCommonNotes();
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  public renderMinInput(): boolean {
    return this.createMode && !this.setTableClicked;
  }

  public renderMaxInput(): boolean {
    return this.createMode && !this.setTableClicked;
  }

  public renderSetTableButton(): boolean {
    return (
      this.setTableReady &&
      !this.setTableClicked &&
      this.createMode &&
      this.inputFormGroup.get('minControl').valid &&
      this.inputFormGroup.get('maxControl').valid
    );
  }

  public setTable(): void {
    this.setTableClicked = true;
    if (this.createMode) {
      this.branchHeaderMax = this.inputFormGroup.get('maxControl').value;
      this.branchHeaderMin = this.inputFormGroup.get('minControl').value;
      this.updateBranchHeaderSizes();
    } else {
      this.branchHeaderMax = this.inputBranchTable.cells[0].headerSize;
      this.branchHeaderMin =
        this.inputBranchTable.cells[
          this.inputBranchTable.cells.length - 1
        ].headerSize;
      this.branchHeaderSizes = [
        ...new Set(this.inputBranchTable.cells.map((cell) => cell.headerSize)),
      ];
      this.sortBranchHeaderSizesAndUpdateRowColCount();
    }
    this.populateCellArray();
    this.updateLegendBranchTableCodes();
    this.updateFormControlsWithLegendCodes();
  }

  private populateSelectedCommonNotes(): void {
    this.commonNotesSelected = this.inputBranchTable.commonNotes;
    this.inputFormGroup.controls['matSelectControl'].setValue(
      this.commonNotesFiltered.filter((note) =>
        this.inputBranchTable.commonNotes.some(
          (branchNote) => branchNote.noteId === note.noteId
        )
      )
    );
  }

  private populateSelectedLegendCommonNotes(): void {
    const namePrefix = 'legendMatSelectControl';
    for (const code of this.legendBranchTableCodes) {
      const notes = this.getCommonNotesFromInputBranchTableByCode(code.code);
      if (notes && notes.length > 0) {
        this.inputFormGroup.controls[namePrefix + code.code].setValue(
          this.commonNotesFiltered.filter((note) =>
            notes.some((branchNote) => branchNote.noteId === note.noteId)
          )
        );
        code.commonNotes = this.commonNotesFiltered.filter((note) =>
          notes.some((branchNote) => branchNote.noteId === note.noteId)
        );
      }
    }
  }

  private getCommonNotesFromInputBranchTableByCode(
    code: string
  ): CommonNoteViewModel[] {
    for (const branchTableCode of this.inputBranchTable.codes) {
      if (branchTableCode.code == code) {
        return branchTableCode.commonNotes;
      }
    }
  }

  private populateCellArray(): void {
    this.cellArray = [];
    for (const branch of this.branchHeaderSizes) {
      const branchArray = [];
      for (const header of this.branchHeaderSizes) {
        if (branch > header) {
          break;
        }
        const cell: BranchTableCellViewModel = this.getEmptyBranchTableCell(
          branch,
          header
        );
        if (this.createMode) {
          cell.code = this.getUnassignedBranchTableCode().code;
        } else {
          const cellData = this.inputBranchTable.cells.filter(
            (cell) => cell.branchSize == branch && cell.headerSize == header
          );
          if (cellData.length > 0) {
            cell.code = this.getCopyOfBranchTableCode(cellData[0]).code;
          }
        }
        branchArray.push(cell);
      }
      this.cellArray.push(branchArray);
    }
  }

  private getEmptyBranchTableCell(
    branch: number,
    header: number
  ): BranchTableCellViewModel {
    const returncell = new BranchTableCellViewModel();
    returncell.branchSize = branch;
    returncell.headerSize = header;
    return returncell;
  }

  private getCopyOfBranchTableCode(
    code: BranchTableCodeViewModel
  ): BranchTableCodeViewModel {
    const newCode: BranchTableCodeViewModel = new BranchTableCodeViewModel();
    newCode.code = code.code;
    newCode.description = code.description;
    newCode.commonNotes = code.commonNotes;
    return newCode;
  }

  private getUnassignedBranchTableCode(): BranchTableCodeViewModel {
    const unassignedCode: BranchTableCodeViewModel =
      new BranchTableCodeViewModel();
    unassignedCode.code = this.unassigned;
    return unassignedCode;
  }

  private updateBranchHeaderSizes(): void {
    this.branchHeaderSizes = this.branchHeaderSizes.filter(
      (size) => size <= this.branchHeaderMax && size >= this.branchHeaderMin
    );
    this.sortBranchHeaderSizesAndUpdateRowColCount();

    if (
      !this.branchHeaderSizes.includes(this.branchHeaderMax) &&
      !this.branchHeaderSizes.includes(this.branchHeaderMin)
    ) {
      this.branchHeaderSizes.push(this.branchHeaderMax);
      this.branchHeaderSizes.push(this.branchHeaderMin);
      this.sortBranchHeaderSizesAndUpdateRowColCount();
    } else if (!this.branchHeaderSizes.includes(this.branchHeaderMax)) {
      this.branchHeaderSizes.push(this.branchHeaderMax);
      this.sortBranchHeaderSizesAndUpdateRowColCount();
    } else if (!this.branchHeaderSizes.includes(this.branchHeaderMin)) {
      this.branchHeaderSizes.push(this.branchHeaderMin);
      this.sortBranchHeaderSizesAndUpdateRowColCount();
    }
  }

  private sortBranchHeaderSizesAndUpdateRowColCount(): void {
    this.branchHeaderSizes.sort((a, b) => b - a);
    this.rowCount = this.branchHeaderSizes.length;
    this.colCount = this.branchHeaderSizes.length;
  }

  public keydownEnterHandlerAdd(event): void {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.addBranchSize();
    }
  }

  public addBranchSize(): void {
    const addedRowNumber = this.inputFormGroup.get('addControl').value;
    if (typeof addedRowNumber === 'number' && !isNaN(addedRowNumber)) {
      this.addRow(addedRowNumber);
    }

    this.inputFormGroup.controls.addControl.reset();
  }

  public disableEnterhandler(event): void {
    event.preventDefault();
  }

  private addRow(addedRowNumber: number) {
    if (this.branchHeaderSizes.includes(addedRowNumber)) {
      return;
    }

    if(this.hiddenRowsArray.includes(addedRowNumber)) {
      this.removeHideChip(addedRowNumber);
    } else {
      this.addedRowsArray.push(addedRowNumber);
      this.branchHeaderSizes.push(addedRowNumber);
      this.sortBranchHeaderSizesAndUpdateRowColCount();
      this.addSizeToCellArray(addedRowNumber);
      this.sortCellArray();
    }
  }

  private addSizeToCellArray(size: number): void {
    const newSubCellArray: BranchTableCellViewModel[] = [];
    this.cellArray.push(newSubCellArray);

    for (const headerSize of this.branchHeaderSizes) {
      if (headerSize >= size) {
        const newCell: BranchTableCellViewModel =
          new BranchTableCellViewModel();
        newCell.branchSize = size;
        newCell.headerSize = headerSize;
        newCell.code = this.unassigned;
        newSubCellArray.push(newCell);
      }
    }

    for (const branchSize of this.branchHeaderSizes) {
      if (branchSize < size) {
        for (const branchRow of this.cellArray) {
          if (branchRow[0].branchSize == branchSize) {
            const newCell: BranchTableCellViewModel =
              new BranchTableCellViewModel();
            newCell.branchSize = branchSize;
            newCell.headerSize = size;
            newCell.code = this.unassigned;
            branchRow.push(newCell);
          }
        }
      }
    }
  }

  private sortCellArray(): void {
    for (const subArray of this.cellArray) {
      subArray.sort((a, b) => b.headerSize - a.headerSize);
    }

    this.cellArray.sort((a, b) => b[0].branchSize - a[0].branchSize);
  }

  public hideRow(hideRowNumber: number) {
    if (!this.branchHeaderSizes.includes(hideRowNumber)) {
      return;
    }

    if(this.addedRowsArray.includes(hideRowNumber)) {
      this.removeAddedChip(hideRowNumber);
    } else {
      this.hiddenRowsArray.push(hideRowNumber);
    }
    
    this.branchHeaderSizes = this.branchHeaderSizes.filter(
      (num) => num !== hideRowNumber
    );
    this.rowCount = this.branchHeaderSizes.length;
    this.colCount = this.branchHeaderSizes.length;
    this.removeSizeFromCellArray(hideRowNumber);
    
    this.updateLegendBranchTableCodes();
  }

  private removeSizeFromCellArray(size: number): void {
    for (let i = 0; i < this.cellArray.length; i++) {
      this.cellArray[i] = this.cellArray[i].filter(
        (cell) => cell.branchSize != size && cell.headerSize != size
      );
    }
    this.cellArray = this.cellArray.filter((row) => row.length > 0);
  }

  public removeHideChip(size: number) {
    this.branchHeaderSizes.push(size);
    this.sortBranchHeaderSizesAndUpdateRowColCount();
    this.addSizeToCellArray(size);
    this.sortCellArray();
    this.hiddenRowsArray = this.hiddenRowsArray.filter(hiddenSizes => hiddenSizes !== size);
  }

  public removeAddedChip(size: number) {
    this.branchHeaderSizes = this.branchHeaderSizes.filter(
      (num) => num !== size
    );
    this.sortBranchHeaderSizesAndUpdateRowColCount();
    this.removeSizeFromCellArray(size);
    this.sortCellArray();
    this.addedRowsArray = this.addedRowsArray.filter(addedSize => addedSize !== size);
  }

  public handleClick(cell: BranchTableCellViewModel): void {
    if (this.readyState) {
      this.startCellBranchCoord = cell.branchSize;
      this.startCellHeaderCoord = cell.headerSize;
      this.endPositionCell = cell;
      this.grabHighlightedCellsToCopyStartCellCode();
    }
    this.cycleTableState();

    if (this.inputState) {
      setTimeout(() => {
        const inputElement = document.getElementById(
          'inputElement' + this.startCellHeaderCoord + this.startCellBranchCoord
        );
        if (inputElement) {
          inputElement.focus();
        }
      }, 0);
    }
  }

  public handleCellEnter(cell: BranchTableCellViewModel): void {
    if (this.highlightState) {
      this.endPositionCell = cell;
      this.grabHighlightedCellsToCopyStartCellCode();
    }
  }

  public handleCellExit(): void {
    if (this.highlightState) {
      this.grabHighlightedCellsToCopyStartCellCode();
    }
  }

  public handleCellEnterKey(cell: BranchTableCellViewModel, event: any): void {
    event.preventDefault();
    const enteredValue = this.inputFormGroup.get('cellInputControl').value;
    if (this.isCodeStringInAvailableCodeArray(enteredValue)) {
      for (const cell of this.highlightedCellArray) {
        cell.code = enteredValue;
      }
      this.updateLegendBranchTableCodes();
      this.cycleTableState();
      this.populateSelectedLegendCommonNotes();
      this.highlightedCellArray = [];
    } else {
      this.snackBarService.showError('You must use a pre-defined code');
    }
  }

  private isCodeStringInAvailableCodeArray(code: string): boolean {
    for (const availableCode of this.availableCodesArray) {
      if (availableCode.code.toLowerCase() == code.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  private updateLegendBranchTableCodes(): void {
    const uniqueCodeStringArray: string[] = [];
    for (const row of this.cellArray) {
      for (const cell of row) {
        if (
          !uniqueCodeStringArray.includes(cell.code) &&
          cell.code !== this.unassigned
        ) {
          uniqueCodeStringArray.push(cell.code);
        }
      }
    }
    //populate legendBranchTableCodes with new codes or remove codes that are no longer in use
    const newLegendBranchTableCodes: BranchTableCodeViewModel[] = [];
    for (const codeString of uniqueCodeStringArray) {
      const newCode: BranchTableCodeViewModel = new BranchTableCodeViewModel();
      newCode.code = codeString;
      newCode.description = this.getCodeDescriptionByCode(codeString);
      newCode.commonNotes = this.getCommonNotesForCode(codeString);
      newLegendBranchTableCodes.push(newCode);
    }
    this.legendBranchTableCodes = newLegendBranchTableCodes;
    this.updateFormControlsWithLegendCodes();
  }

  private getCommonNotesForCode(code: string): CommonNoteViewModel[] {
    const returnArray: CommonNoteViewModel[] = [];
    for (const legendCode of this.legendBranchTableCodes) {
      if (legendCode.code == code) {
        return legendCode.commonNotes;
      }
    }
    return returnArray;
  }

  private grabHighlightedCellsToCopyStartCellCode(): void {
    this.highlightedCellArray = [];
    for (const branchRow of this.cellArray) {
      for (const headerCol of branchRow) {
        if (this.isCellInHighlightedRegion(headerCol)) {
          this.highlightedCellArray.push(headerCol);
        }
      }
    }
  }

  public isCellInHighlightedRegion(cell: BranchTableCellViewModel): boolean {
    let inRegion = false;
    let endCellBranchCoord;
    let endCellHeaderCoord;
    if (this.endPositionCell) {
      endCellHeaderCoord = this.endPositionCell.headerSize;
      endCellBranchCoord = this.endPositionCell.branchSize;
    }

    if (
      cell.branchSize == this.startCellBranchCoord &&
      cell.headerSize == this.startCellHeaderCoord
    ) {
      return true;
    }
    //NE region
    else if (
      endCellHeaderCoord <= this.startCellHeaderCoord &&
      endCellBranchCoord >= this.startCellBranchCoord
    ) {
      inRegion = this.isCellInNERegion(
        cell,
        endCellBranchCoord,
        endCellHeaderCoord
      );
    }
    //NW region
    else if (
      endCellHeaderCoord >= this.startCellHeaderCoord &&
      endCellBranchCoord >= this.startCellBranchCoord
    ) {
      inRegion = this.isCellInNWRegion(
        cell,
        endCellBranchCoord,
        endCellHeaderCoord
      );
    }
    //SW region
    else if (
      endCellHeaderCoord >= this.startCellHeaderCoord &&
      endCellBranchCoord <= this.startCellBranchCoord
    ) {
      inRegion = this.isCellInSWRegion(
        cell,
        endCellBranchCoord,
        endCellHeaderCoord
      );
    }
    //SE region
    else if (
      endCellHeaderCoord <= this.startCellHeaderCoord &&
      endCellBranchCoord <= this.startCellBranchCoord
    ) {
      inRegion = this.isCellInSERegion(
        cell,
        endCellBranchCoord,
        endCellHeaderCoord
      );
    }
    return inRegion;
  }

  private isCellInNERegion(
    cell: BranchTableCellViewModel,
    endCellBranchCoord: number,
    endCellHeaderCoord: number
  ): boolean {
    return (
      cell.branchSize <= cell.headerSize &&
      cell.branchSize <= endCellBranchCoord &&
      cell.branchSize >= this.startCellBranchCoord &&
      cell.headerSize <= this.startCellHeaderCoord &&
      cell.headerSize >= endCellHeaderCoord
    );
  }

  private isCellInNWRegion(
    cell: BranchTableCellViewModel,
    endCellBranchCoord: number,
    endCellHeaderCoord: number
  ): boolean {
    return (
      cell.branchSize <= cell.headerSize &&
      cell.branchSize <= endCellBranchCoord &&
      cell.branchSize >= this.startCellBranchCoord &&
      cell.headerSize >= this.startCellHeaderCoord &&
      cell.headerSize <= endCellHeaderCoord
    );
  }

  private isCellInSWRegion(
    cell: BranchTableCellViewModel,
    endCellBranchCoord: number,
    endCellHeaderCoord: number
  ): boolean {
    return (
      cell.branchSize <= cell.headerSize &&
      cell.branchSize >= endCellBranchCoord &&
      cell.branchSize <= this.startCellBranchCoord &&
      cell.headerSize >= this.startCellHeaderCoord &&
      cell.headerSize <= endCellHeaderCoord
    );
  }

  private isCellInSERegion(
    cell: BranchTableCellViewModel,
    endCellBranchCoord: number,
    endCellHeaderCoord: number
  ): boolean {
    return (
      cell.branchSize <= cell.headerSize &&
      cell.branchSize >= endCellBranchCoord &&
      cell.branchSize <= this.startCellBranchCoord &&
      cell.headerSize <= this.startCellHeaderCoord &&
      cell.headerSize >= endCellHeaderCoord
    );
  }

  public isInput(cell: BranchTableCellViewModel): boolean {
    if (
      this.inputState &&
      cell.branchSize == this.startCellBranchCoord &&
      cell.headerSize == this.startCellHeaderCoord
    ) {
      return true;
    } else {
      return false;
    }
  }

  private cycleTableState(): void {
    if (this.readyState) {
      this.readyState = false;
      this.highlightState = true;
    } else if (this.highlightState) {
      this.highlightState = false;
      this.inputState = true;
    } else if (this.inputState) {
      this.inputState = false;
      this.readyState = true;
    }
  }

  public getCellClass(cell: BranchTableCellViewModel): string {
    if (!this.readyState && this.highlightedCellArray.includes(cell)) {
      return 'highlight-cell';
    } else {
      return 'data-cell';
    }
  }

  public selectCommonNotes(event): void {
    this.commonNotesSelected = event.value;
  }

  public onKeyCommonNotes(value): void {
    this.commonNotesFiltered = this.searchCommonNotesAll(value);
    this.inputFormGroup.controls['matSelectControl'].setValue(
      this.commonNotesSelected
    );
  }

  private searchCommonNotesAll(value: string): CommonNoteViewModel[] {
    let returnArray: CommonNoteViewModel[] = [];
    const filter = value.toLowerCase();
    returnArray = this.commonNotesAll.filter(
      (option) =>
        option.note.toLowerCase().includes(filter) ||
        option.codeId.toLowerCase().startsWith(filter)
    );
    returnArray = returnArray.concat(this.commonNotesSelected);
    return returnArray;
  }

  public onKeyCommonNotesLegend(
    value: string,
    code: BranchTableCodeViewModel
  ): void {
    this.commonNotesFiltered = this.searchCommonNotesAllLegend(value, code);
    this.inputFormGroup.controls['legendMatSelectControl' + code.code].setValue(
      code.commonNotes
    );
  }

  private searchCommonNotesAllLegend(
    value: string,
    code: BranchTableCodeViewModel
  ): CommonNoteViewModel[] {
    let returnArray: CommonNoteViewModel[] = [];
    const filter = value.toLowerCase();
    returnArray = this.commonNotesAll.filter(
      (option) =>
        option.note.toLowerCase().includes(filter) ||
        option.codeId.toLowerCase().startsWith(filter)
    );
    returnArray = returnArray.concat(code.commonNotes);
    return returnArray;
  }

  public removeSelectedNote(note: CommonNoteViewModel): void {
    this.commonNotesSelected.forEach((item, index) => {
      if (item.noteId === note.noteId) {
        this.commonNotesSelected.splice(index, 1);
      }
    });
    this.inputFormGroup.controls['matSelectControl'].setValue(
      this.commonNotesSelected
    );
  }

  public removeSelectedNoteForCode(
    note: CommonNoteViewModel,
    code: BranchTableCodeViewModel
  ): void {
    code.commonNotes.forEach((item, index) => {
      if (item.noteId === note.noteId) {
        code.commonNotes.splice(index, 1);
      }
    });
    this.inputFormGroup.controls['legendMatSelectControl' + code.code].setValue(
      code.commonNotes
    );
  }

  public selectCommonNotesForCode(
    event: CommonNoteViewModel[],
    code: BranchTableCodeViewModel
  ): void {
    code.commonNotes = event;
  }

  public getCodeDescriptionByCode(code: string): string {
    for (const availableCode of this.availableCodesArray) {
      if (
        availableCode.code === code ||
        availableCode.code.toLowerCase() === code
      ) {
        return availableCode.description;
      }
    }
  }

  public getCodeIdByCode(code: string): string {
    for (const availableCode of this.availableCodesArray) {
      if (availableCode.code == code) {
        return availableCode.id;
      }
    }
  }

  private populateBranchTableFromForm(branchTable: BranchTableViewModel): BranchTableViewModel {
    branchTable = new BranchTableViewModel();
    branchTable.name = this.inputFormGroup.get('tableName').value;
    branchTable.commonNotes = this.commonNotesSelected;
    branchTable.type = this.getBranchTableType();
    branchTable.cells = this.getCells();
    branchTable.codes = this.legendBranchTableCodes;

    if (!this.createMode) {
      branchTable.branchTableId = this.inputBranchTable.branchTableId;
    }

    return branchTable;
  }

  private getBranchTableType(): string {
    const toggleState = this.inputFormGroup.get('typeToggleControl').value;
    if (toggleState) {
      return this.fortyFiveDegrees;
    } else {
      return this.nintyDegrees;
    }
  }

  public getBranchCodeFromCell(cell: BranchTableCellViewModel): string {
    return cell.code;
  }

  private getCells(): BranchTableCellViewModel[] {
    const returnCellArray: BranchTableCellViewModel[] = [];
    for (const row of this.cellArray) {
      for (const cell of row) {
        if (cell.code !== this.unassigned) {
          const newCell: BranchTableCellViewModel =
            new BranchTableCellViewModel(cell);
          returnCellArray.push(newCell);
        }
      }
    }
    return returnCellArray;
  }

  public allCellsPopulated(): boolean {
    for (const row of this.cellArray) {
      for (const cell of row) {
        if (cell.code === this.unassigned) {
          return false;
        }
      }
    }
    return true;
  }

  public exitButton(): void {
    if (this.setTableClicked) {
      const modalRef = this.dialog.open(ConfirmDialogComponent, {
        width: '80%',
        height: '30%',
        data: {
          dialogMessage: this.confirmationDialogMessage,
        },
      });
      modalRef.afterClosed().subscribe((result) => {
        if (result) {
          window.close();
        }
      });
    } else {
      window.close();
    }
  }

  public saveButton(): void {
    this.saveClicked = true;
    if (this.createMode) {
      if (this.inputFormGroup.valid && this.allCellsPopulated()) {
        this.submissionBranchTableVM = this.populateBranchTableFromForm(this.submissionBranchTableVM);
        this.esraApiClient
          .createBranchTable(this.submissionBranchTableVM)
          .subscribe({
            next: (newBranchTable) => {
              this.snackBarService.showSnackBar(
                true,
                `The branch table, ${newBranchTable.name} was successfully created`
              );
              this.router.navigate(['/piping/dictionaries/branch']);
            },
            error: (err) => {
              this.snackBarService.showSnackBar(
                true,
                `Sorry, an error occurred. Error: ${err.message}`
              );
            },
          });
      }
    } else if (this.inputFormGroup.valid && this.allCellsPopulated()) {
      this.submissionBranchTableVM = this.populateBranchTableFromForm(this.submissionBranchTableVM);
      this.esraApiClient
        .updateBranchTable(this.submissionBranchTableVM)
        .subscribe({
          next: (branchTable) => {
            this.snackBarService.showSnackBar(
              true,
              `The branch table, ${branchTable.name} was updated`
            );
            this.router.navigate(['/piping/dictionaries/branch']);
          },
          error: (err) => {
            this.snackBarService.showSnackBar(
              true,
              `Sorry, an error occurred. Error: ${err.ValidationErrors}`
            );
          }
        });
    }

    if (this.inputFormGroup.invalid) {
      this.inputFormGroup.markAllAsTouched();
    }
  }

  private nonEmptyStringValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value;
    if (
      value === '' ||
      (typeof value === 'string' && value.trim().length === 0)
    ) {
      return { nonEmptyString: true };
    }
    return null;
  }

  private greaterThanZeroAndDivisibleByQuarterValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value;
    if (value && (value <= 0 || value % 0.25 !== 0)) {
      return { greaterThanZeroAndDivisibleByQuarter: true };
    }
    return null;
  }
}
