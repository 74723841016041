// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-checkbox {
  display: flex;
  align-content: flex-start;
  margin: 10px 8px auto 0;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
}
.mat-mdc-checkbox .mat-checkbox-layout {
  white-space: normal !important;
}
.mat-mdc-checkbox .mat-checkbox-inner-container {
  margin-top: 5px;
}

mat-tab-group .mdc-tab {
  font-weight: bold;
  color: #000000;
}
mat-tab-group .mdc-tab--active {
  color: #0066b2;
}

mat-expansion-panel-header .mat-content {
  flex: 0;
  flex-basis: content;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #0066b2;
  color: white;
}

mat-radio-button .mat-mdc-radio-button .mat-radio-outer-circle {
  height: 15px;
  width: 15px;
  border-color: #0066b2;
}
mat-radio-button .mat-mdc-radio-button .mat-radio-label-content {
  font-size: 9px;
  padding-left: 0px;
}
mat-radio-button .mat-mdc-radio-button .mat-radio-label {
  white-space: inherit;
  vertical-align: 0%;
  line-height: initial;
}

.mat-select-panel mat-option.mat-mdc-option {
  margin: auto 0;
  padding: 10px 20px;
  overflow: visible;
  line-height: 130%;
  word-wrap: break-word;
  white-space: pre-wrap;
  height: 100%;
}

.mat-snack-bar-warning .mdc-snackbar__surface {
  background: rgb(255, 248, 115) !important;
}

.mat-snack-bar-warning .mdc-snackbar__label {
  color: black !important;
  font-weight: bold !important;
}

.mat-snack-bar-error {
  background-color: #97002e;
}
.mat-snack-bar-error .mdc-snackbar__surface {
  --mdc-snackbar-container-color: \$esra-red;
  --mat-mdc-snack-bar-button-color: rgb(250, 193, 192);
}

.mat-mdc-card {
  box-shadow: none;
}

.dialogButtons {
  padding-top: 10px;
  float: right;
}

.button-blue {
  background-color: #0066b2;
  border: 1px solid #0066b2;
  color: #ffffff;
}
.button-blue:hover {
  background-color: #0b2d71;
  cursor: pointer;
}

.button {
  margin: none;
}

.text-button {
  display: inline-block;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: #0066b2;
  font-family: "GothamBold", Arial, sans-serif;
}
.text-button:hover {
  color: #0b2d71;
  box-shadow: inset 0 -2px 0 0 #0b2d71;
}

.close-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.close-button::before {
  font-family: "Material Icons";
  content: "close";
}
.close-button:hover {
  background-color: rgba(0, 0, 0, 0.16);
  color: #0b2d71;
}

:root ngx-slider {
  transition: none;
  margin: 15px 0px;
  height: 40px;
}
:root ngx-slider .ngx-slider-pointer {
  width: 12px;
  height: 12px;
  top: auto;
  bottom: 26px;
  background-color: #0066b2;
}
:root ngx-slider .ngx-slider-bubble {
  top: auto;
  bottom: 0px;
}
:root ngx-slider .ngx-slider-span {
  color: rgba(0, 0, 0, 0.8705882353);
  font: normal normal medium "Gotham", Arial, sans-serif;
  font-size: 11px;
}
:root ngx-slider .ngx-slider-pointer:after {
  display: none;
}
:root ngx-slider .ngx-slider-bar {
  height: 2px;
  background: rgba(125, 127, 134, 0.7019607843);
  top: auto;
  bottom: 7px;
}
:root ngx-slider .ngx-slider-selection {
  background: #0066b2;
  top: auto;
  bottom: 7px;
}

app-welding-search .mat-mdc-paginator .mat-mdc-paginator-container {
  display: flex;
  justify-content: center;
}

/* You can add global styles to this file, and also import other style files */
.left-align {
  float: left;
}

.right-align {
  float: right;
}

.text-left-align {
  text-align: left !important;
}

.text-right-align {
  text-align: right !important;
}

.dialogClose {
  float: right;
  cursor: pointer;
}

.dialogClose div {
  text-align: center;
  margin-top: -6px;
}

.dialogmainheader {
  padding-top: 30px;
}

.dialogmain {
  border: 1px solid gray;
  min-height: 300px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -15px;
}

.button:enabled,
.primary:enabled,
.secondary:enabled {
  border: 1px solid #0066b2;
}

.button:disabled,
.primary:disabled,
.secondary:disabled {
  border: 1px solid #e3e3e3;
  cursor: default;
}

.container-horizontal {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.required:after {
  content: " *";
  color: red;
}

.pointer {
  cursor: pointer;
}

.progress-bar-container {
  min-height: 6px;
  margin: 2% 3%;
}
.progress-bar-container div {
  height: 5px;
}

.esra-select {
  width: 80%;
}

.width-10 {
  width: 10%;
}

.esra-tooltip {
  white-space: pre-line;
}

.edit-color {
  color: #0066b2;
}

.delete-color {
  color: #e21836;
}

.mat-sort-header-arrow {
  opacity: 1 !important;
  transform: none;
  color: black;
}

.wrapped-text {
  padding: 1% 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.text-button {
  display: inline-block;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: #0066b2;
  font-family: "GothamBold", Arial, sans-serif;
}
.text-button:hover {
  color: #0b2d71;
  box-shadow: inset 0 -2px 0 0 #0b2d71;
}

.main-pane {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2%;
}

.left-align {
  float: left;
}

.right-align {
  float: right;
}

.text-left-align {
  text-align: left;
}

.text-right-align {
  text-align: right;
}

.draft {
  background-color: #faab18;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}

.inreview {
  background-color: #0091a4;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}

.waitingforapproval {
  background-color: #0091a4;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}

.rejected {
  background-color: #e21836;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}

.published {
  background-color: #0b2d71;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}

.circle .draft {
  background-color: #faab18;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}
.circle .inreview {
  background-color: #0091a4;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}
.circle .waitingforapproval {
  background-color: #0091a4;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}
.circle .rejected {
  background-color: #e21836;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}
.circle .published {
  background-color: #0b2d71;
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}

.created-by-container {
  display: flex;
}
.created-by-container .name {
  font-weight: bold;
}
.created-by-container .date {
  color: #8c8f93;
  margin-left: 3%;
}

.buttonDiv {
  display: flex;
  margin-right: 1%;
}
.buttonDiv button {
  margin-left: 5%;
  margin-right: 5%;
}

.replyButtonDiv {
  display: flex;
  margin-right: 3%;
}
.replyButtonDiv button {
  margin-left: 5%;
  margin-right: 5%;
}

.comment-reply-body {
  display: flex;
}
.comment-reply-body textarea {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.comment-reply-main-body {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.comment-reply-main-body .revison-comment-status {
  justify-content: flex-end;
  margin-right: 1%;
}

.comment-reply-container {
  border: solid #e3e3e3;
  border-width: 1px;
  width: 100%;
  padding: 1%;
}

.comment-main-pane-with-reply {
  width: 100%;
  display: flex;
  margin-bottom: 2%;
  flex-direction: column;
}

.comment-main-pane {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reply-main-pane {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5%;
}

.user-card-header span {
  margin-top: 4px;
}

.user-card-container {
  padding: 1%;
}

.reply-button {
  padding: 1%;
}

.highlighted {
  background-color: #e4f4fa;
}

.main-reply {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.5% 1%;
}

.flex-grow {
  flex-grow: 5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
